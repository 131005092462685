import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state: any, action) => {
      const existingItem = state.items.find(
        (item: any) => item.productId[0] === action.payload.productId[0]
      );
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
    },
    removeItem: (state, action) => {
      const itemIdToRemove = action.payload.productId[0];
      state.items = state.items.filter(
        (item: any) => item.productId[0] !== itemIdToRemove
      );
    },
    resetCart: (state) => {
      state.items = [];
    },
  },
});

export const { addItem, removeItem, resetCart } = cartSlice.actions;

export default cartSlice.reducer;
