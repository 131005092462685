import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const _ = require('lodash');

const initialState = {
  products: [],
  loading: false,
  error: null,
};

export const getProducts = createAsyncThunk(
  'products/get',
  async (limit, { getState, rejectWithValue }) => {
    const { products }: any = getState(); // Get the current state

    // Check if products are already loaded, if yes, return the existing products
    if (products.products.length > 10) {
      return products.products;
    }

    try {
      const productsResponse = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID_PRODUCTS}/environments/master/entries?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN_PRODUCTS}&content_type=product&limit=${limit}`
      );
      const imagesResponse = await axios.get(
        `https://cdn.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID_PRODUCTS}/environments/master/assets?access_token=${process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN_PRODUCTS}&limit=${limit}`
      );

      const products = productsResponse.data.items?.map((product: any) => {
        let imageArray: any[] = [];

        let idArray: any[] = [];
        let updatedDateArray: any[] = [];

        product.fields.productImages?.forEach((image: any) => {
          const imageLocation = imagesResponse.data.items.find((asset: any) => {
            return asset.sys.id === image.sys.id;
          });
          imageArray.push(imageLocation);
        });

        product.fields.productImages = imageArray;
        idArray.push(product.sys.id);
        product.fields.productId = idArray;

        updatedDateArray.push(product.sys.updatedAt);
        product.fields.updatedAt = updatedDateArray;

        return product.fields;
      });
      return products;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.products = action.payload;
        state.error = null;
      })
      .addCase(getProducts.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default productSlice.reducer;
